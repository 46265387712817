<template>
  <div>
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn
        variant="warning"
        href="#/master-data/customer"
        class="ml-auto text-white"
        style="width: 7em;"
        >Kembali</b-btn
      >
      <!-- <b-btn variant="primary" :href="'#/master-data/user/' + $route.params.id + '/edit'" class="ml-2" style="width: 7em;">Edit</b-btn> -->
    </div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{ $route.name }}</h5>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col
            :key="index"
            cols="4"
            v-if="
              (typeof m.disabled === 'undefined' || !m.disabled) &&
                m.type !== 'hidden'
            "
          >
            <detail-group-input
              :key="index"
              :type="m.type"
              :label="typeof m.label === 'string' ? m.label : null"
              :value="m.value"
              :id="index"
            ></detail-group-input>
          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-col>
          <div class="my-3">
            <b-button
              :to="'/order?user_id=' + this.$route.params.id"
              variant="primary"
              v-b-tooltip.hover.right
              title="Click for more order detail!"
              >Detail Order</b-button
            >
            <b-button class="ml-3" @click="sendRegeneratePassword" variant="primary"
              >Reset Password</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import User from "@/models/User.js";

const userModel = new User();

export default {
  components: {
    detailGroupInput,
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      model: userModel.init(),
      data: null
    };
  },
  mounted() {
    this.model.password.type = "hidden";
    this.getDetail();
  },
  methods: {
    sendRegeneratePassword: function() {
      userModel.sendRegeneratePassword(this.data.id).then(() => {
        this.$store.dispatch(
          "notification/success",
          "Sms Update Password Berhasil Dikirim."
        );
      }).catch(() => {
        this.$store.dispatch("notification/error", "Sms Update Password Gagal Dikirim.");
      })
    },
    getDetail() {
      this.isLoading = true;
      userModel
        .find(this.$route.params.id)
        .then((resp) => {
          this.isLoading = false;
          setModelValue(this.model, resp);
          this.data = resp
          this.model.role.value = resp.role.toLocaleUpperCase();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
