import axios from "axios";
import BaseModel from "@/models/BaseModel.js";

export default class User extends BaseModel {
  fields = {
    name: {
      type: "text",
      label: "nama",
      rules: "required",
    },
    email: {
      type: "text",
      rules: "required|email",
    },
    phone_number: {
      type: "text",
      rules: "required",
    },
    password: {
      type: "password",
      rules: "required|min:5",
    },
    role: {
      type: "enum",
      options: {
        admin: "Admin",
        staff: "Staff",
        "customer service": "Costomer Service",
        "back office": "Back Office",
        manifest: "Manifest",
      },
      rules: "required",
    },
  };
  endpoint = process.env.VUE_APP_API_URL + "users";

  sendRegeneratePassword(userId) {
    let data = new FormData();
    data.append("user_id", userId);
    data.append("_method", "POST");
    return new Promise((resolve, reject) => {
      axios
        .post(this.baseUrl + "pilgrim/send-new-password", data)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }
}
